import React from 'react'
import { Link, graphql } from 'gatsby'
import BackgroundImage from "gatsby-background-image"

import Layout from '../../components/layout'
import SEO from '../../components/seo'

function Blog({ data }) {
  const { edges: postEdges }  = data.allBlogPosts
  const { nodes: imageNodes }  = data.allBlogPostImages
  
  return (
    <Layout>
      <SEO
        title="Blog"
        keywords={[`gortek`, `app`, `startup`, `business`]}
      />

      <section className="flex flex-col">
        <h2 className="w-full text-primary">
          Featured Posts
        </h2>
        <div className="flex flex-wrap sm:-mx-3 mx-0">
          {postEdges.map(edge => {
            const post = edge.node.frontmatter
            const image = imageNodes.find(node => node.relativePath.slice(7) === post.coverPhoto)

            return (
              <div
                key={edge.node.id}
                className="md:w-1/3 sm:w-1/2 w-full p-3 flex"
              >
                <Link
                  to={post.path}
                  className="w-full shadow rounded overflow-hidden"
                >
                  <BackgroundImage
                    fluid={image.childImageSharp.fluid}
                    style={{
                      height: '10rem',
                      backgroundSize: 'cover',
                      backgroundPosition: 'center'
                    }}
                  />
                  <h6 className="font-bold px-3 pt-3">
                    {post.title}
                  </h6>
                  <p className="p-3 text-sm text-gray-600">
                    {post.subtitle || <>&nbsp;</>}
                  </p>
                </Link>
              </div>
            )
          })}
        </div>
      </section>

    </Layout>
  )
}

export default Blog

export const postQuery = graphql`
  query {
    allBlogPosts: allMarkdownRemark(filter: {frontmatter: {draft: {eq: false}}}) {
      edges {
        node {
          id
          frontmatter {
            title
            subtitle
            path
            coverPhoto
          }
        }
      }
    }
    allBlogPostImages: allFile(filter: {relativeDirectory: {eq: "images"}, sourceInstanceName: {eq: "posts"}}) {
      nodes {
        relativePath
        childImageSharp {
          fluid(maxWidth: 1000) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`
